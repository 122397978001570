<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="addCustomerAddress" v-right-code="'Customeraddress:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="deleteCustomerAddress" v-right-code="'Customeraddress:Deleteaddress'">删除</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="importCustomerAddress" v-right-code="'Customeraddress:Importaddress'">导入</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="exportCustomerAddress" v-right-code="'Customeraddress:Exportfilesender'">导出</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="customerAddressListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ContactName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" :min-width="130" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="setDefault(scope.row)" :disabled="scope.row.DisplayIsDefaultAddress=='是'" v-right-code="'Customer_Right_BasicInfo_SendCustomerAddress_SetDefault'" size="small" type="text">设为默认地址</el-button>
                            <el-button @click="cancelDefault(scope.row)" :disabled="scope.row.DisplayIsDefaultAddress=='否'" v-right-code="'Customer_Right_BasicInfo_SendCustomerAddress_SetDefault'" size="small" type="text">取消默认地址</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <DialogEx :options="importConfig" title="导入发货地址" @onOk="onImportOk">
            <ImportCustomer ref="importCustomer" :options="importConfig" :addressType="importConfig.addressType"></ImportCustomer>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("reloadCustomerAddressPageList", () => this.reloadPageList());
                this.Event.$on("onAddCustomerAddress", () => this.addCustomerAddress());
            });
            //this.Event.$on("reloadCustomerAddressPageList", () => this.reloadPageList());
            //this.Event.$on("onAddCustomerAddress", () => this.addCustomerAddress());
            this.initialize();
        },
        data() {
            return {
                tUserOptions: {
                    visible: false
                },
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                importConfig: {
                    addressType: 0,
                    visible: false,
                    size: 'small',
                }
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/senderaddress/searchsenderaddresslist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/customeraddress/getcustomeraddressbyid", "get", { id: row.CustomerAddressID }, (data) => {
                    this.Event.$emit("clearEditCustomerAddressForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            setDefault(row) {
                var _this = this;
                this.$confirm('确定设为常用地址吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax.send("omsapi/customeraddress/setdefaultaddress", "post", row , (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            cancelDefault(row) {
                var _this = this;
                _this.$confirm('确定要取消默认地址吗', '提示', {
                    comfirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _this.$ajax.send("omsapi/customeraddress/canceldefaultaddress", "post", row, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                    _this.message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            addCustomerAddress() {
                this.$ajax.send("omsapi/customeraddress/getemptycustomeraddress", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            deleteCustomerAddress() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择客户地址.`, "error");
                    return false;
                }
                this.Utils.confirm({
                    title: "是否删除地址?",
                    content: "删除后数据内容会发生改变,是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/customeraddress/deleteaddress", "post", this.multipleSelection, (data) => {
                        this.onPageChange(this.queryParam);
                        this.Utils.messageBox("地址删除成功.", "success");
                    });
                }, () => {
                    this.Utils.messageBox("已取消删除.", "info");
                });
            },
            importCustomerAddress() {
                var _this = this;
                _this.importConfig.addressType = 100;
                _this.importConfig.visible = true;
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            onImportOk() { },
            exportCustomerAddress: function () {
                var _this = this;
                var data = _this.queryParam;
                data.params.AddressType = { "fieldName": "AddressType", "fieldValueStart": "1990-01-01T00:00:00.000Z", "fieldValueEnd": "1990-01-01T00:00:00.000Z", "fieldLabel": "收货类型", "fieldType": 5, "fieldValue": "100" }; 
                var str = encodeURIComponent(JSON.stringify(data));
                var urlFile = "omsapi/customeraddress/ExportSendAddress?queryParam=" + str;
                _this.Utils.export(urlFile);
                delete data.params.AddressType;
            }
        },
        components: {
            "ImportCustomer": resolve => { require(['../../../customer/components/importcustomeradd.vue'], resolve) },
        }

    }
</script>
<style>
</style>